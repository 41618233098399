<template>
  <footer class="flex justify-center py-24 md:py-36">
    <section class="w-screen max-w-1120px px-6 xl:px-0 flex flex-col gap-16">
      <div class="flex justify-between items-center">
        <router-link to="/" aria-label="Xposure">
          <img
            src="https://ik.imagekit.io/xposuremusic/logo.svg"
            alt="Xposure"
            width="24"
            height="24"
          />
        </router-link>
        <div class="flex gap-8 justify-center">
          <OutgoingLink href="https://www.instagram.com/xposuremusic/">
            <i class="icon-instagram text-20 hover:text-accent-orange" />
          </OutgoingLink>
          <OutgoingLink href="https://x.com/xposuremusic">
            <i class="icon-x text-20 hover:text-accent-orange" />
          </OutgoingLink>
          <OutgoingLink href="https://www.tiktok.com/@xposuremusic">
            <i class="icon-tiktok text-20 hover:text-accent-orange" />
          </OutgoingLink>
        </div>
      </div>
      <div class="border-grayscale-20 border-t">
        <Link
          target="_self"
          href="https://info.xposuremusic.com/funding"
          additionalClasses="text-20 flex items-center justify-between py-5 border-b border-grayscale-20 hover:text-accent-orange"
        >
          <p>Get Funding</p>
          <i class="icon-arrow-right" />
        </Link>
      </div>

      <div class="grid-cols-6 gap-10 text-13 hidden md:grid">
        <div v-for="col in data" :key="col.title" class="flex flex-col gap-4">
          <p class="font-bold">{{ col.title }}</p>
          <div class="flex flex-col gap-2 text-grayscale-90">
            <Link
              class="hover:text-accent-orange"
              v-for="item in col.links"
              :key="item.label"
              :to="item.to"
              :href="item.href"
              :label="item.label"
              target="_self"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col md:hidden border-t border-grayscale-20">
        <AccordionGroup v-for="col in data" :key="col.title" :title="col.title">
          <div class="flex flex-col gap-2 text-13 text-grayscale-90">
            <Link
              class="hover:text-accent-orange"
              v-for="item in col.links"
              :key="item.label"
              :to="item.to"
              :href="item.href"
              :label="item.label"
              target="_self"
            />
          </div>
        </AccordionGroup>
      </div>
      <p class="text-grayscale-60 text-xs">
        All names, logos, and brands of third parties (e.g., Warner, Sony,
        Universal and their respective logos) displayed on our site are
        trademarks of their respective owners. Xposure Music and its products
        and services are not endorsed by, sponsored by, or affiliated with these
        third parties. Our use of these names, logos, and brands is for
        identification purposes only, and does not imply any endorsement,
        sponsorship, or affiliation.
        <br />
        <br />
        Copyright © {{ currentYear }} Xposure Technology Inc. All rights
        reserved.
      </p>
    </section>
  </footer>
</template>

<script setup lang="ts">
import Link from "@/components/layout/buttons/Link.vue";
import { City, Genre, ProJobTitle, XposureServices } from "@/types/user";
import { genreSlugify, slugify } from "@/types/directory";
import AccordionGroup from "@/components/layout/tabs/AccordionGroup.vue";
import OutgoingLink from "@/components/layout/buttons/OutgoingLink.vue";
import dayjs from "dayjs";

const currentYear = dayjs().year();

const data: {
  title: string;
  links: {
    label: string;
    to?: string;
    href?: string;
  }[];
}[] = [
  {
    title: "Xposure Services",
    links: [
      {
        label: "Music Funding",
        href: `https://info.xposuremusic.com/funding`,
      },
      {
        label: "Connect with Music Pros",
        to: `/industry-pros`,
      },
      {
        label: "Music Royalty Calculator",
        href: `https://info.xposuremusic.com/music-royalty-calculator`,
      },
      {
        label: "Music Catalog Sales",
        href: `https://info.xposuremusic.com/sell-music-catalog`,
      },
    ],
  },
  {
    title: "Blog",
    links: [
      {
        label: "Music Business",
        href: `https://info.xposuremusic.com/topic/music-business`,
      },
      {
        label: "Music Production",
        href: `https://info.xposuremusic.com/topic/music-production`,
      },
      {
        label: "Music Distribution",
        href: `https://info.xposuremusic.com/topic/music-distribution`,
      },
      {
        label: "Artist Development",
        href: `https://info.xposuremusic.com/topic/artist-development`,
      },
      {
        label: "News",
        href: `https://info.xposuremusic.com/topic/xposure-news`,
      },
    ],
  },
  {
    title: "Support & Legal",
    links: [
      {
        label: "Get Support",
        href: `https://support.xposuremusic.com/knowledge/kb-tickets/new`,
      },
      {
        label: "Apply to be a Music Pro",
        to: `/apply-as-industry-pro`,
      },
      {
        label: "Privacy Policy",
        href: `https://info.xposuremusic.com/legal/privacy-policy`,
      },
      {
        label: "Terms of Service",
        href: `https://info.xposuremusic.com/legal/terms-of-service`,
      },
    ],
  },
  {
    title: "Find a Pro by Category",
    links: Object.values(ProJobTitle).map((jobSlug) => {
      return {
        label: `${jobSlug}s`,
        to: `/industry-pros/${slugify(jobSlug)}`,
      };
    }),
  },
  {
    title: "Find a Pro by Genre",
    links: Object.values(Genre).map((genreSlug) => {
      return {
        label: genreSlugify(genreSlug),
        to: `/industry-pros/${slugify(genreSlug)}`,
      };
    }),
  },
  {
    title: "Find a Pro by Location",
    links: Object.values(City).map((citySlug) => {
      return {
        label: citySlug,
        to: `/industry-pros/${slugify(citySlug)}`,
      };
    }),
  },
];
</script>
